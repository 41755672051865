<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-book" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      SPORTS
                    </div>
                    <div class="text-sm text-500">
                      Details of sports list record
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container">
        <div class=" card grid ">

          <div class="col-12 text-xl  font-bold">
            Add New Sport
          </div>
          <div class="col-12 md:col-5">
            <div class="mb-2 font-bold text-sm">
              Label *
            </div>
            <div id="ctrl-label-holder">
              <InputText ref="ctrllabel" v-model.trim="label" label="Label" type="text" placeholder="Label"
                class="w-full">
              </InputText>
            </div>
          </div>
          <div class="col-12 md:col-5">
            <div class="mb-2 font-bold text-sm">
              Value *
            </div>
            <div id="ctrl-value-holder">
              <InputText ref="ctrlvalue" v-model.trim="value" label="Value" type="text" placeholder="Value"
                class="w-full">
              </InputText>
            </div>
          </div>
          <div class="col-12 md:col-2">
            <Button class="p-button-primary w-full md:mt-4" type="button" label="Submit" icon="pi pi-send"
              @click="addNewData" :loading="saving" />
          </div>
        </div>
        <hr class="my-4" />
        <div class="grid">
          <div class="col comp-grid">
            <div class="col-12 text-xl  font-bold">
              Sport List
            </div>
            <div class="">
              <div>
                <div class="relative-position" style="min-height: 100px">
                  <template v-if="!loading && item">
                    <DataTable :loading="loading" :value="item.config_data" dataKey="value" paginator :rows="10"
                      :rowsPerPageOptions="[5, 10, 20, 50]" :showGridlines="false" :rowHover="true"
                      responsiveLayout="stack" v-model:editingRows="editingRows" @row-edit-save="onRowEditSave"
                      editMode="row">

                      <Column field="label" header="Label">
                        <template #body="{ data }">
                          {{ data.label }}
                        </template>
                        <template #editor="{ data, field }">
                           <InputText v-model="data[field]" class="w-full" />
                        </template>
                      </Column>

                      <Column field="value" header="Value">
                        <template #body="{ data }">
                          {{ data.value }}
                        </template>
                        <template #editor="{ data, field }">
                           <InputText v-model="data[field]" class="w-full" />
                        </template>
                      </Column>
                      <Column :rowEditor="true" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
                      </Column>
                      <Column headerStyle="width: 3em" headerClass="text-center">
                        <template #body="{ data }">
                          <Button icon="pi pi-trash" class="p-button-danger p-button-text" @click="deleteData(data)" />
                        </template>
                      </Column>

                    </DataTable>

                  </template>
                  <template v-if="loading">
                    <div class="p-3 text-center">
                      <ProgressSpinner style="width: 50px; height: 50px" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
import { ApiService } from "../../services/api.js";
export default {
  name: "viewDataConfigPage",
  components: {},
  mixins: [PageMixin, ViewPageMixin],
  props: {
    pageName: {
      type: String,
      default: "data_config",
    },
    idName: {
      type: String,
      default: "config_id",
    },
    routeName: {
      type: String,
      default: "data_configview",
    },
    pagePath: {
      type: String,
      default: "data_config/view",
    },
    apiPath: {
      type: String,
      default: "data_config/view/sports_list",
    },
  },
  data() {
    return {
      item: {
        default: {},

      },
      editingRows: [],
      label: "",
      value: "",
      saving: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "SHS Details";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["data_config/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("data_config/setCurrentRecord", value);
      },
    },
  },
  meta() {
    return {
      title: this.pageTitle,
    };
  },
  methods: {
    ...mapActions("data_config", ["fetchRecord", "deleteRecord"]),
   


    async addNewData() {
      this.saving = true
      if (!this.label || !this.value) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill all the required fields',
          life: 3000
        })
        this.saving = false
        return
      }

      // Check if the value already exists in the config_data array
      const exists = this.item.config_data.some(item => item.value == this.value || item.label == this.label);

      if (exists) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'This value already exists.',
          life: 3000
        });
        this.saving = false; // Stop further processing if the value exists
        return;
      }
      this.item.config_data.push({ label: this.label, value: this.value })
      console.log(this.item)
      await ApiService.post("data_config/add", this.item).then(resp => {
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Data added successfully',
          life: 3000
        })
        this.resetForm()
        this.load();
      })
        .catch(err => {
          reject(err)
        })
      this.saving = false
    },



    async onRowEditSave(event) {
      // Save changes back to the main data array
      const { newData, index } = event;
      
      if (!newData.label || !newData.value) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'All fields are required',
          life: 3000
        })
        this.saving = false
        return
      }
      this.item.config_data[index] = newData;
      await ApiService.post("data_config/add", this.item).then(resp => {
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Data added successfully',
          life: 3000
        })
        this.load();
      })
        .catch(err => {
          reject(err)
        })
    },


    async deleteData(data) {
      let title = "Delete record";
      let prompt = "Are you sure you want to delete this record?";

      this.$confirm.require({
        message: prompt,
        header: title,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.item.config_data = this.item.config_data.filter(
            itemValue => !(itemValue.value == data.value && itemValue.label == data.label)
          );
          console.log(this.item)
          ApiService.post("data_config/delete", this.item).then(resp => {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Data deleted successfully',
              life: 3000
            })
            this.load();
          })
            .catch(err => {
              reject(err)
            })
        },
        reject: () => {
          //callback to execute when user rejects the action
        }
      });
      console.log(data)
    },



    resetForm() {
      this.label = ""
      this.value = ""
    }

  },
  watch: {
    $route(to, from) {
      //only fetch data when navigated to this page
      if (to.name == this.routeName) {
        //this.load();
      }
    },
  },
  async mounted() { },
  async created() { },
};
</script>
<style scoped></style>
